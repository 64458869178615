<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <div style="width:550px">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入销售合伙人/销售合伙人手机号/合伙人昵称/合伙人手机号搜索"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>

            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class=" marginBottom10"
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class=" marginBottom10"
              >重置</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
                class="marginBottom10"
              >导出</el-button>
            </div>

          </div>
        </div>
      </template>

    </avue-crud>
  </div>
</template>

<script>
import { bindingRelationshipList } from "@/api/region/commission";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "绑定关系列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "合伙人编号",
            prop: "code",
          },
          {
            label: "合伙人昵称",
            prop: "nickname",
          },
          {
            label: "合伙人手机号",
            prop: "phone",
          },
          {
            label: "推广时间",
            prop: "createTime",
          },
          {
            label: "贡献的收益/元",
            prop: "incomePrice",
          },
          {
            label: "销售合伙人",
            prop: "partnerUsername",
          },
          {
            label: "销售合伙人手机号",
            prop: "partnerPhone",
          },
        ],
      },
      form: {
        id: "",
        type: "",
        name: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      const searchData = {
        name: this.searchData.name,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      bindingRelationshipList({
        userId: this.$route.query.id,
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')

      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 导出
    handleExport() {
      const searchData = {
        name: this.searchData.name,
        userId: this.$route.query.id,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      window.open(
        `/api/web/partner/bind/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.password-box {
  background: #e6f7ff;
  border: 2px solid #02a7f0;
  padding: 20px;
  position: relative;

  .icon {
    color: #faad14;
    position: absolute;
    left: 20px;
    top: 24px;
    font-size: 24px;
  }
  .password-content {
    padding: 0 40px;

    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .password-copy {
    font-size: 16px;
  }
}
</style>